import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Link } from 'components/atoms';

import { useAllCaseStudyWorkArray, useOptions } from 'graphql/hooks';

const findNextWork = (currentId, worksList) => {
  const currentIndex = worksList.findIndex(item => item.id === currentId);
  const nextWork = worksList[currentIndex + 1];

  const nextWorkData =
    worksList.length - 1 > currentIndex ? nextWork : worksList[0];

  if (nextWorkData) {
    const { title, path } = nextWorkData;

    return { title, path };
  }
};

const NextWorkCaseStudy = ({ className, data }) => {
  const { id: current_id } = data;

  const caseStudies = useAllCaseStudyWorkArray();
  const options = useOptions().options;
  const featuredWorksNumber = Number(options.featured_works_number);

  const caseStudyWorkArray = caseStudies.filter((c, index) => {
    if (index < featuredWorksNumber) {
      return true;
    }
    return !c.acf.private_access && (c.acf.logo || c.acf.excerpt);
  });

  const sectionTitle = 'Next case study';

  const nextWork = findNextWork(current_id, caseStudyWorkArray);
  const { title: nextWorkTitle, path: nextWorkLink } = nextWork;

  return (
    <Section className={cn('next-work-case', [className])}>
      <Container>
        <Row>
          <Column>
            <Text className="next-work-case__subheader subheader">
              {sectionTitle}
            </Text>
            <Link className="next-work-case__link" href={nextWorkLink}>
              <Text tag="h2" className="headline-1">
                {nextWorkTitle}
              </Text>
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

NextWorkCaseStudy.defaultProps = {
  className: '',
};

NextWorkCaseStudy.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default NextWorkCaseStudy;
